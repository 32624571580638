import React from "react";
import './Slider.css'
import Sample from "../Assets/Sample.jpg"
import HealthCare from "../Assets/HealthCare.jpg"
import Facility from "../Assets/FacilityService.jpg"
import KmsGroup from "../Assets/KMSGroup.jpg"
import ControlRoom from "../Assets/ControlRoom.jpg"
import Consultancy from "../Assets/ConsultancyService.jpg"
import BgVerify from "../Assets/BG-Verify.jpg"
import Security from "../Assets/Security.jpg"

function Images({ s }) {
    const imgStyle = {
      width: 100 + "%",
      height: "auto"
    };
    return <img src={s} alt="slider-img" style={imgStyle} />;
  }
  
  function Slider() {
    const sliderArr = [
        KmsGroup,
        Security,
        Facility,
        HealthCare,
        BgVerify,
        Consultancy,
        ControlRoom,
    ];
  
    const [x, setX] = React.useState(0);
    const [isPlaying, setIsPlaying] = React.useState(true);
  
    React.useEffect(() => {
      let countDown = null;
      if (isPlaying) {
        countDown = setInterval(() => goRight(), 2500);
      } else {
        clearInterval(countDown);
      }
      return () => clearInterval(countDown);
    });
  
    const goLeft = () => {
      setIsPlaying(false);
      x === 0 ? setX(-100 * (sliderArr.length - 1)) : setX(x + 100);
      setIsPlaying(true);
    };
  
    const goRight = () => {
      setIsPlaying(false);
      x === -100 * (sliderArr.length - 1) ? setX(0) : setX(x - 100);
      setIsPlaying(true);
    };
  
    return (
      <div className="slider77">
        {sliderArr.map((el, i) => (
          <div
            className="slide77"
            key={i}
            style={{ transform: `translateX(${x}%)` }}
          >
            <Images s={el} />
          </div>
        ))}
        <button className="slider-buttons" onClick={goLeft} id="goLeft">
        
          
          <i class="fas fa-angle-left"></i>
        </button>
        <button className="slider-buttons" onClick={goRight} id="goRight">  <i class="fas fa-angle-right"></i>
        </button>
      </div>
    );
  }
  
  function AppSlide() {
    return (
      <div className="App">

        
        <Slider />
      </div>
    );
  }
  export default AppSlide;
  
  
  
