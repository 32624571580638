import React, { useRef,useState } from 'react';
import "./Contact.css"
import emailjs from '@emailjs/browser';
import CntHero from "../../Copmponents/Assets/Contact-hero.jpg"

function Contact() {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_kzj1e6w', 'template_ro0r50o', form.current, 'eD4-jRQasI6i02QqU')
                        // serviceID,TempleteID, PublicKey
      .then(
        (result) => {
          console.log(result.text);
          // Clear form fields after successful submission
          form.current.reset();
          // Show the "Thank you" popup
          setSubmitted(true);
          setTimeout(function () {
            window.location = "/";
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
  
      );
  };
  return (
  <>
  <div className='contac-hero-img'>
    <img src={CntHero} alt=''/>
  </div>
    <div className='contact-page'>
        <div><h1>We are currently in service cities.</h1></div>
        <div className='Locations-container'>
            <div className='cities-location Hyderabd-c'>
                <h3>HYDERABAD</h3>
            </div>
            <div className='cities-location Banglore-c'>
                <h3>BANGALORE</h3>
                </div>
            <div className='cities-location Chennai-c'>
                <h3>CHENNAI</h3> </div>
            <div className='cities-location Vizag-c'>
                <h3>VIZAG</h3> </div>
            <div className='cities-location Vijayawada-c'> 
            <h3> VIJAYAWADA</h3>
        </div>

        </div>
    <div class="container">
    <div class="content">
      <div class="left-side">
        <div class="address details">
          <i class="fas fa-map-marker-alt"></i>
          <div class="topic">Address</div>
          <div class="text-one">Flat no:107, Arjun block,First floor, Krushi defence colony</div>
          <div class="text-two">Hyderabad - 502319</div>
        </div>
        <div class="phone details">
          <i class="fas fa-phone-alt"></i>
          <div class="topic">Phone</div>
          <div class="text-one">+919100235133</div>
          <div class="text-two">+919381571961</div>
        </div>
        <div class="email details">
          <i class="fas fa-envelope"></i>
          <div class="topic">Email</div>
          <div class="text-one"></div>
          <div class="text-two">connect@kmsgroup.online</div>
        </div>
      </div>
      <div class="right-side">
        <div class="topic-text">Send us a message</div>
        <p>If you are looking for any services or any types of quries related our company, you can send us message from here.</p>
     
     
      <form ref={form} onSubmit={sendEmail}>
        <div class="input-box">
          <input name="fullName" type="text"  required placeholder="Enter your full name"/>
        </div>
        <div class="input-box">
          <input type="tel" maxLength={10}  required name="mobile" placeholder="Enter your mobile number"/>
        </div>
        <div class="input-box">
          <input name="email" type="email"  required placeholder="Enter your email"/>
        </div>
        <div class="input-box message-box">
            <textarea name ="message" placeholder=' Enter your message'/>
          
        </div>
        <button className="Submit-btn" type="sumbit">
                    Submit
                  </button>
      </form>
      {submitted && (
          <div className="success-message">
            <p>Thank you for submitting the form! <br />
                        Your information has been received and our
                        representative will be in touch with you soon.</p>
          </div>
        )}

    </div>
    </div>
  </div>
  </div>
  </>
  )
}

export default Contact