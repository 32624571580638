import React from "react";
import HeroSecurity from "../../Copmponents/Assets/Security.jpg";
import "./Security.css";

function Security() {
  return (
    <div>
      <div className="hero-security-22">
        <img src={HeroSecurity} />
      </div>
      {/* ------- */}
      <div className="security-content">
        <h2>Unleash unparalleled security solutions with KMS Groups!</h2>
        <p>
          Secure your peace of mind with our comprehensive security solutions.
          Whether it's safeguarding buildings, offices, or commercial spaces, we
          offer tailored security systems to meet your unique requirements. Our
          expert team utilizes advanced technology and industry best practices
          to ensure your safety and protection.
        </p>
      </div>
      {/* ---------- */}
      <div className="heading33">
        <h1>Unlock your security potential with our strategic partnership.</h1>
      </div>
      <div className="features-box-container">
        <div className="feature-box">
          <h2>Exceptional Customer Service Strategy</h2>
          <p>
            Diverse feedback channels for customers, with a proactive and
            solution-oriented approach. A variety of avenues for customer
            feedback, coupled with a proactive and results-driven mindset.
          </p>
        </div>
        <div className="feature-box">
          <h2>Tailored Security Solutions</h2>
          <p>
            Our customized approach ensures compliance and ethical solutions
            that align with major market governance.
            <br />
            <b>segments and industry:</b> IT/ITES, Manufacturing, Retail, BFSI,
            Telecom, A flat organizational structure making E-commerce,
            Embassies, Education, us agile and highly responsive. Healthcare,
            Hospitality.
          </p>
        </div>
        <div className="feature-box">
          <h2>Technology</h2>
          <p>
            Data-driven approach & Digitilized internal process
            <br />
            Facial recognition based automated attendance system for on site
            gurds.
          </p>
        </div>
        <div className="feature-box">
          <h2>Flat Organizational Agility</h2>
          <p>
            Operating with a flat organizational setup, we embody agility and
            exceptional responsiveness.
          </p>
        </div>
        <div className="feature-box">
          <h2>Personalized Security</h2>
          <p>
            {" "}
            Our security personnel are professionally trained and verified,
            ensuring personalized service that meets your unique requirements.
          </p>
        </div>
        <div className="feature-box">
          <h2>Delivery of Security Concept</h2>
          <p>
            Ability to seamlessly execute a standardized security concept across
            diverse regions and branches.
          </p>
        </div>
      </div>
      {/* ----------------- */}
      <div className="heading-sf-13">
        <h1>Predictive Security: Unleashing Value, Minimizing Risk</h1>
      </div>
      {/* ----------- */}
      <div className="heading-sf-13">
        <h2> Securitas
Electronic Security Solutions</h2>
      </div>
      <div className="features-securatus">
        <div className="security99 sf-1">
        <h2>CCTV access & intruder alarm System</h2>
        </div>
        <div className="security99 sf-2" >
            <h2>Fire alarm & building management</h2>
        </div>
        <div className="security99 sf-3">
            <h2>Remote video solutions</h2>
        </div>
        <div className="security99 sf-4">
            <h2>Automation & digitalazation solutions</h2>
        </div>
        <div className="security99 sf-5">
            <h2>Security system maintenance</h2>
        </div>
      </div>
    </div>
  );
}

export default Security;
