import React from "react";
import "./Aboutus.css";
import OurApproach from "../../Copmponents/Assets/OurApc-Abt.jpg"
import ScrtAbt from "../../Copmponents/Assets/SecuritySolutions-ABT.jpg"
import FasilityAbt from "../../Copmponents/Assets/Facility-Abt.jpg"
import CommitAbt from "../../Copmponents/Assets/Commitment-Abt.jpg"
import PartAbt from "../../Copmponents/Assets/PartnerWithus-Abt.jpg"
import AboutHero from "../../Copmponents/Assets/Abt-hero.jpg";

function Aboutus() {
  return (
    <>
      <div className="Abt-hero">
        <img src={AboutHero} />
      </div>
      {/* ----------- */}
      <div className="abt-col-1">
        <h1>Welcome to KMS Groups!</h1>
        <p>
          At KMS Groups, we are committed to providing exceptional services in
          the realm of security solutions, facility management, and HR services.
          With our diverse range of expertise, we strive to create a secure and
          efficient environment for businesses across various industries.
        </p>
      </div>
      {/* -------------- */}
      <div className="abt-ctn-main">
      <div className="abt-col-2">
        <div className="left-img-abt">
            <img src={OurApproach}/>
        </div>
        <div className="right-content-abt">
            <h2>Our Approach</h2>
          <p>
            We take pride in our customer-centric approach, tailoring our
            services to meet the unique requirements of each client. By
            understanding your specific needs and challenges, we develop
            customized solutions that prioritize your safety, operational
            efficiency, and peace of mind.
          </p>
        </div>
        
      </div>

        <div className="abt-col-2">
        <div className="right-content-abt">
        <h2>Security Solutions</h2>
          <p>
            We take pride in our customer-centric approach, tailoring our
            services to meet the unique requirements of each client. By
            understanding your specific needs and challenges, we develop
            customized solutions that prioritize your safety, operational
            efficiency, and peace of mind.
          </p>
        </div>
        <div className="left-img-abt">
            <img src={ScrtAbt}/>
        </div>
      </div>

      <div className="abt-col-2">
        <div className="left-img-abt">
            <img src={FasilityAbt}/>
        </div>
        <div className="right-content-abt">
            <h2>Facility Solutions</h2>
          <p>
            We take pride in our customer-centric approach, tailoring our
            services to meet the unique requirements of each client. By
            understanding your specific needs and challenges, we develop
            customized solutions that prioritize your safety, operational
            efficiency, and peace of mind.
          </p>
        </div>
      </div>

      <div className="abt-col-2">
        <div className="right-content-abt">
            <h2>Commitment to Excellence</h2>
          <p>
            We take pride in our customer-centric approach, tailoring our
            services to meet the unique requirements of each client. By
            understanding your specific needs and challenges, we develop
            customized solutions that prioritize your safety, operational
            efficiency, and peace of mind.
          </p>
        </div>
        <div className="left-img-abt">
            <img src={CommitAbt}/>
        </div>
      </div>

      <div className="abt-col-2">
        <div className="left-img-abt">
            <img src={PartAbt}/>
        </div>
        <div className="right-content-abt">
            <h2>Partner with Us</h2>
          <p>
            We take pride in our customer-centric approach, tailoring our
            services to meet the unique requirements of each client. By
            understanding your specific needs and challenges, we develop
            customized solutions that prioritize your safety, operational
            efficiency, and peace of mind.
          </p>
        </div>
      </div>
      </div>

    </>
  );
}

export default Aboutus;
