import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
    <div className="footer-container">
      <div className="Locations-info">
        <h2>Employee compliance</h2>
        <p>Securitas India | KMS Groups Services</p>
        <h2>Our offices:</h2>
        <p>Bengaluru | Chennai | Hyderabad | Vijayawada | Vizag </p>
      </div>
      <div className="Address-footer">
        <h2>Address:</h2>
        <p>
          Flat no:107, Arjun block,First floor, Krushi defence colony Hyderabad
          - 502319
        </p>
        <h2>Contact:</h2>
        <p>
          +919100235133
          <br />
          +919381571961
        </p>
        <p>connect@kmsgroup.online</p>
      </div>
    </div>
    <div className="Copyrights">
      <p>Copyright Ⓒ 2021 KMS GROUPS SERVICES PVT. LTD. All Rights Reserved </p>
       </div>
    </>
  );
}

export default Footer;
