import React from "react";
import Slider from "../../Copmponents/Slider/Slider";
import { Link } from "react-router-dom";
import "./Home.css";
import LT from "../../Copmponents/Assets/L&T.jpg";
import LW from "../../Copmponents/Assets/bokoda.jpg";
import Tata from "../../Copmponents/Assets/tata.jpg";
import Al from "../../Copmponents/Assets/Alwa.jpg";
import SecurityBox from "../../Copmponents/Assets/securityBox.jpg";
import FacilityBox from "../../Copmponents/Assets/FacilityBox.jpg";
import HealthBox from "../../Copmponents/Assets/HealthBox.jpg";
import BgBox from "../../Copmponents/Assets/BgBox.jpg";

function Home() {
  return (
    <div>
      <Slider />
      <div className="col-22">
        <div className="col-22-inner">
          <h1>Welcome to KMS Groups!</h1>
          <p>
            At KMS Groups, we are dedicated to providing top-notch services in
            the field of Security Solutions, Healthcare Staffing, Background
            Verification Services and Facility Solutions. With a wealth of
            experience spanning five years, we have established ourselves as a
            trusted name in the industry.
          </p>
        </div>
      </div>

      {/* ----------------------- Services---------------------- */}
      <div className="Services-container">
        <Link
          to="/security-services"
          className="Services-box Security-solutions"
        >
          <h2> Security Solutions</h2>
        </Link>
        <Link
          to="/Facility-services"
          className="Services-box Facility-solutions"
        >
          <h2>Facility Solutions</h2>
        </Link>
        <Link
          to="/background-verification-services"
          className="Services-box Bg-solutions"
        >
          <h2>Background Verification Services</h2>
        </Link>
        <Link className="Services-box Health-solutions">
          <h2> Healthcare Staffing</h2>
        </Link>
      </div>
      {/* ---------cities-------------- */}
      <div className="Home-cities">
        <div>
          <h1>We are currently in service cities.</h1>
        </div>
        <div className="Locations-container">
          <div className="cities-location Hyderabd-c">
            <h3>HYDERABAD</h3>
          </div>
          <div className="cities-location Banglore-c">
            <h3>BANGALORE</h3>
          </div>
          <div className="cities-location Chennai-c">
            <h3>CHENNAI</h3>
          </div>
          <div className="cities-location Vizag-c">
            <h3>VIZAG</h3>
          </div>
          <div className="cities-location Vijayawada-c">
            <h3> VIJAYAWADA</h3>
          </div>
        </div>
      </div>

      {/* ------Clients--------- */}
      <div className="clients-container">
        <div className="Clients-heading">
          <h1>Our Esteemed Clients.</h1>
        </div>
        <div className="client-logos-container">
          <div className="Client-logo">
            <img src={Tata} alt="Kms group client" />
          </div>
          <div className="Client-logo">
            <img src={LT} alt="Kms group client" />
          </div>
          <div className="Client-logo">
            <img src={LW} alt="Kms group client" />
          </div>
          <div className="Client-logo">
            <img src={Al} alt="Kms group client" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
