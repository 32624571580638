import React from 'react'
import { BrowserRouter,Route, Routes } from 'react-router-dom'
import Home from "./Pages/Home/Home"
import Navbar from "./Copmponents/Navbar/Navbar"
import Contact from "./Pages/ContactUs/Contact"
import Footer from "./Copmponents/Footer/Footer"
import Security from "./Pages/Security/Security"
import AboutUs from './Pages/AboutUs/Aboutus'
import Fasility from './Pages/Facility/Facility'
import BgVerify from './Pages/BgVerify/Bgverify'

function App() {
  return (
    <div className='App'>
      
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exat path='/contact' element={<Contact/>}/>
          <Route  path='/security-services' element={<Security/>}/>
          <Route  path='/about-us' element={<AboutUs/>}/>
          <Route  path='/Facility-services' element={<Fasility/>}/>
          <Route  path='/background-verification-services' element={<BgVerify/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App
// import React from "react";
// import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
// import { useLayoutEffect } from "react";
// import Home from "./Pages/Home/Home";
// import Navbar from "./Copmponents/Navbar/Navbar";
// import Contact from "./Pages/ContactUs/Contact";
// import Footer from "./Copmponents/Footer/Footer";
// import Security from "./Pages/Security/Security";
// import AboutUs from "./Pages/AboutUs/Aboutus";

// function App() {
//   const Wrapper = ({ children }) => {
//     const location = useLocation();
//     useLayoutEffect(() => {
//       document.documentElement.scrollTo(0, 0);
//     }, [location.pathname]);
//     return children;
//   };

//   return (
//     <div className="App">
//       <Wrapper>
//         <Router>
//           <Navbar />
//           <Routes>
//             <Route exact path="/" element={<Home />} />
//             <Route exact path="/contact" element={<Contact />} />
//             <Route path="/security-services" element={<Security />} />
//             <Route path="/about-us" element={<AboutUs />} />
//           </Routes>
//           <Footer />
//         </Router>
//       </Wrapper>
//     </div>
//   );
// }

// export default App;
