import React from 'react'
import FasHero from '../../Copmponents/Assets/FacilityService.jpg'
import "./Facility.css"

function Facility() {
  return (
    <>
    <div className='fas-hero'>
        <img src={FasHero}/>

    </div>
    <div className='fas-cards-main'>   


<div className="flip">
    <div className="front fas-card1"> 
       <h1 className="text-shadow"> Soft Services</h1>
    </div>
    <div className="back">
       <h2>Soft Services</h2>
       <p>From meticulous housekeeping services to reliable office and business support, we take care of all your facility needs. Our dedicated staff ensures a clean and well-maintained environment, allowing you to focus on what matters most—your business.</p>
    </div>
</div>
<div className="flip">
    <div className="front fas-card2" >
       <h1 className="text-shadow">Building Management</h1>
    </div>
    <div className="back">
       <h2>Building Management</h2>
       <p>Efficiently managing your building is our priority. We handle everything from maintenance and repairs to optimizing operational processes. With our expertise, we streamline operations, enhance efficiency, and create a pleasant experience for occupants and visitors.

</p>
    </div>
</div>
<div className="flip">
    <div className="front fas-card3" >
       <h1 className="text-shadow">HR Services</h1>
    </div>
    <div className="back">
       <h2>HR Services</h2>
       <p>Our HR solutions cater to your staffing needs, enabling you to build a talented workforce. We offer recruitment assistance, payroll management, and other HR-related services, allowing you to focus on strategic business initiatives while we take care of the administrative aspects.</p>
    </div>
</div>
<div className="flip">
    <div className="front fas-card4" >
       <h1 className="text-shadow">Disinfection Services</h1>
    </div>
    <div className="back">
       <h2>Disinfection Services</h2>
       <p>In the face of evolving health challenges, our disinfection services ensure a safe and sanitized environment. Using industry-approved protocols and effective disinfectants, we help minimize the risk of infections, creating a healthier and more secure space for everyone.</p>
    </div>
</div>
</div>

    </>
  )
}

export default Facility