import React from 'react'
import "./Bgverify.css"
import BgHero from "../../Copmponents/Assets/BG-Verify.jpg"

function Bgverify() {
  return (
  <>
    <div className='bg-verify-hero'>
        <img src={BgHero}/>
    </div>
    <div className='bgv-col2'>
        <h1>Verify & Fortify</h1>
        <p>Over the years, KMS Groups has been instrumental in assisting numerous leading corporations and organizations in India to mitigate the risk associated with hiring employees with questionable backgrounds. With our extensive experience of five years, we have established ourselves as a trusted provider of comprehensive background verification services tailored to the specific needs of our clients.</p>
        <p> With a strong presence across India, KMS Groups has emerged as a preferred source of background verification for organizations across diverse industries. Our clientele includes IT/ITES, Education and Institutions, Telecom, Hospitality, Finance, Retail, Government & High Commissions, BFSI, and Manufacturing sectors.</p>
        <p>Choose KMS Groups as your trusted partner for comprehensive background verification services in India. With our five years of experience and a laser focus on the Indian market, we are committed to delivering reliable and accurate results that safeguard your organization's interests and ensure a secure and trustworthy workforce.</p>
    </div>
    <div className='bg-services-col3'> 
        <div className='Bg-b2b'>
            <h2>B2B & B2G verification:</h2>
            <div className='bg-service-list'> 
                    <li>Pre & Post Employment Verification</li>
                    <li>Leadership Verification</li>
                    <li>Vendor Due Diligence</li>
                    <li>KYC (Know Your Customer)</li>
                    <li>Pre & Post Insurance Verification</li>
                    <li>Medical Certificates Verification</li>
                    <li>Risk and Claim Investigation</li>
                    <li>Company Due Diligence</li>
                    <li>Volunteer Background Check</li>
                    <li>Skip Tracing</li>
            </div>
            
        </div>
        <div className='Bg-b2b'>
            <h2>B2C – Individual verification:</h2>
            <div className='bg-service-list'>
                <li>Self-Background Check</li>
                <li>Support Staff Verification</li>
                <li>Tenant Verification</li>
                <li>Driver Verification</li>
                <li>Matrimonial Verification</li>
                <li>Loan Eligibility Verification</li>
              
            </div>
            
         </div>
    </div>
    {/* --------------- */}
    <div className='bg-verify-service'>
        <div className='bgv-content-text'>
            <h1>Our services</h1>
            <h2>Verify and Protect Your Organization with Robust Employment Background Checks</h2>
            <p>Securitas Background Screening empowers employers with valuable insights about candidates prior to forming professional associations. In a highly competitive job market, candidates may strategically withhold certain work history details to enhance their appeal to recruiters. However, through our thorough background checks, we unveil the complete truth. We recognize the significance of informed hiring decisions and provide comprehensive screening services to offer a deeper understanding of candidates. With Securitas Background Screening, you can make confident and transparent recruitment choices that align with your organization's requirements and values. Trust us to uncover candidate potential and ensure a reliable and trustworthy workforce.</p>
        </div>
    </div>
    {/* ---------------- */}
    <div className='ServicesList-Bg'>
        <div className='ServiceItem SL-1'>
            <p><b>Residential verification</b></p>
        </div>
        <div className='ServiceItem SL-2'>
            <p><b>Academic verification </b></p>
        </div>
        <div className='ServiceItem SL-3'>
            <p><b>Previous employment verification</b></p>
        </div>
        <div className='ServiceItem SL-4'>
            <p><b>Reference verification</b></p>
        </div>
        <div className='ServiceItem SL-5'>
            <p><b>OFAC check</b></p>
        </div>
        <div className='ServiceItem SL-6'>
            <p><b>Exit interviews</b></p>
        </div>
        <div className='ServiceItem SL-7'>
            <p><b>Gap verification</b></p>
        </div>
        <div className='ServiceItem SL-8'>
            <p><b>Criminal record verification</b></p>
        </div>
        <div className='ServiceItem SL-9'>
            <p><b>Drug test</b></p>
        </div>
        <div className='ServiceItem SL-10'>
            <p><b>Vendor due diligence</b></p>
        </div>
        <div className='ServiceItem SL-11'>
            <p><b>Leadership Verification</b></p>
        </div>
        <div className='ServiceItem SL-12'>
            <p><b>Tenant verification</b></p>
        </div>
    </div>
</>
  )
}

export default Bgverify